@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  
  .animate-float {
    animation: float 6s ease-in-out infinite;
  }
  
  .galaxy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    background-color: white;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    opacity: 0.3;
    animation: twinkle 5s infinite;
  }
  
  @keyframes twinkle {
    0% { opacity: 0.3; }
    50% { opacity: 1; }
    100% { opacity: 0.3; }
  }
  
  .star:nth-child(even) {
    animation-duration: 4s;
  }
  
  .animate-text {
    background-size: 200% auto;
    animation: textShine 5s linear infinite;
  }
  
  @keyframes textShine {
    to {
      background-position: 200% center;
    }
  }
  
  @media (max-width: 640px) {
    .galaxy {
      opacity: 0.5;
    }
    
    .animate-float {
      animation: float 4s ease-in-out infinite;
    }
  }

  .current-product-image-container {
    width: 100%;
    padding-bottom: 100%; /* Creates a square aspect ratio */
    position: relative;
  }
  
  .current-product-image-container > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  @media (min-width: 768px) {
    .current-product-image-container {
      padding-bottom: 75%; /* Adjust this value to change the aspect ratio on desktop */
    }
  }